.blue-gradient {
  background: linear-gradient(90deg, #BBD9FB -1.68%, rgba(187, 217, 251, 0.00) 99.98%)
}

.pulse-animation {
  animation: pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
    transform: translateY(0);;
  }

  20% {
    opacity: 0.1;
    transform: translateY(-2px);;
  }
}

.loading-background {
  position: fixed;
  top: 52px;
  height: 95vh;
  background-color: white;
  z-index: 30;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  animation: slideUp 0.2s ease forwards;
}

.modal-backdrop-light {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(226, 223, 223, 0.5);
  z-index: 10;
}

.modal-backdrop-dark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 10;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.centered-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
}