.table-row-light {
  border-width: 0.5px;
  border-style: solid;
  border-bottom: #D8D8D8;
}

.table-row-dark {
  border-width: 0.5px;
  border-style: solid;
  border-bottom: #273037;
}

/* .table-row-even-light {
  background-color: #F0F3F9;
}

.table-row-odd-light {
  background-color: #FFFFFF99;
}

.table-row-dark {
  background-color: #FFFFFF00;
}

.table-row-even-dark {
  background-color: #FFFFFF0F;
}

.table-row-odd-dark td:first-child,
.table-row-even-dark td:first-child,
.table-row-odd-light td:first-child,
.table-row-even-light td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-row-odd-dark td:last-child,
.table-row-even-dark td:last-child,
.table-row-odd-light td:last-child,
.table-row-even-light td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
} */

.ant-table-tbody > tr > td:first-child {
  padding-right: 0px !important; 
}

.ant-table-tbody > tr > td:nth-child(2) {
  padding-left: 8px !important; 
}

.ant-table-column-sort {
  background-color: transparent !important;
}

.table-header-no-border th {
  border-bottom: none !important;
}

.ant-table-wrapper {
  overflow-x: hidden;
  margin-bottom: 30px;
}

.ant-table-column-sorter-up, 
.ant-table-column-sorter-down {
  color: rgb(201, 201, 201);
}