@keyframes appear-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

.animate-button {
  opacity: 0;
  animation: appear-from-bottom 0.3s ease-in-out forwards;
}