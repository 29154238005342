.TVChartContainer {
    height: calc(100vh - 80px);
}

.buttonAdjusted {
    background-color: #5843F8;
    color: white;
    padding: 5px 20px;
    transition-duration: 0.2s;

}

.buttonAdjusted:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
